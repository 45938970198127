import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { appActions } from '../../../../store/actions/app.actions';
import { WindowService } from '../../../../shared/services/window/window.service';
import { ImageCardComponent } from '../image-card/image-card.component';
import { ImagePillComponent } from '../image-pill/image-pill.component';
import { filter, first } from 'rxjs';
import { EventType, IUpsell } from '../../../../shared/services/event-request/event-request.type';
import { selectEvent } from '../../../../store/selectors/event.selectors';
import { BasicHeaderComponent } from '../../../../ui/components/basic-header/basic-header.component';
import { EnhancedFormsLauncherService } from '../../../../shared/services/enhanced-forms-launcher/enhanced-forms-launcher.service';
import { FormPartialEnum } from '../../../../shared/enums/partial-status.enum';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
  standalone: true,
  imports: [CommonModule, ImageCardComponent, ImagePillComponent, BasicHeaderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RecommendationsComponent implements OnInit, OnDestroy {
  panelOpen = false;
  cardUpsells: IUpsell[] = [];
  pillUpsells: IUpsell[] = [];
  @Input() showSidebar = false;
  @Input() eventType = '';

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _store: Store,
    private _windowService: WindowService,
    private _enhancedFormsLauncherService: EnhancedFormsLauncherService
  ) {}

  ngOnInit(): void {
    if (!this._windowService.isMobile()) {
      setTimeout(() => {
        this._store.dispatch(appActions.ToggleShowingSidenav({ payload: false }));
      });
    }
    this.subscribeToEvent();
  }

  setPanelOpen(isOpen) {
    this.panelOpen = isOpen;
  }

  /**
   * Opens the V3 form if we have a form definition for that event type. Falls back to the v1 form.
   *
   * Note that the form will automatically redirect to the new event on successful submission.
   */
  openForm(upsell: IUpsell) {
    const eventUuid = this._activatedRoute.snapshot.paramMap.get('eventUuid');
    if (upsell.v2FormUuid) {
      this._enhancedFormsLauncherService.launchForm({
        formUuid: upsell.v2FormUuid,
        eventUuid,
        mainEventUuid: eventUuid
      });
    } else {
      this._windowService.open(upsell.url, '_blank');
    }
  }

  private subscribeToEvent(): void {
    this._store.dispatch(appActions.IncrementLoadingCount());
    this._store
      .select(selectEvent.getEvent)
      .pipe(
        filter(event => event !== null),
        first()
      )
      .subscribe({
        next: ({ formStage, event, partialFormUuid, upsells: { serviceRelated = [], eventSpecific = [], eventType = [] } }) => {
          this._store.dispatch(appActions.DecrementLoadingCount());

          this.tryLaunchSupplementaryForm(formStage, partialFormUuid, event.uuid, event);

          const combinedUpsells = [...eventSpecific, ...serviceRelated, ...eventType];
          const dedupedUpsells = this.getDedupedUpsells(combinedUpsells);

          this.cardUpsells = dedupedUpsells.slice(0, 4);
          this.pillUpsells = dedupedUpsells.slice(4, 12);
        },
        error: msg => {
          console.error(msg);
          this._store.dispatch(appActions.DecrementLoadingCount());
        }
      });
  }

  private getDedupedUpsells(combinedUpsells: IUpsell[]) {
    return combinedUpsells.filter((a, index) => combinedUpsells.findIndex(b => b.tid === a.tid) == index);
  }

  private tryLaunchSupplementaryForm(formStage: FormPartialEnum, partialFormUuid: string, eventUuid: string, event: EventType) {
    const isPartialPending = formStage === FormPartialEnum.PARTIAL_PENDING;
    const hasPartialCompletionFormUuid = !!partialFormUuid;

    if (isPartialPending && hasPartialCompletionFormUuid) {
      const model = this._enhancedFormsLauncherService.getModelFromEvent(event);

      this._enhancedFormsLauncherService.launchForm({ formUuid: partialFormUuid, eventUuid, model });
    }
  }

  /**
   * Navigate to the event overview page.
   */
  goToEventOverviewPage() {
    const { paramMap } = this._activatedRoute.snapshot;
    const eventUuid = paramMap.get('eventUuid');
    const eventRequestUuid = paramMap.get('eventRequestUuid');

    this._router.navigate(['event-request', eventUuid, eventRequestUuid]);
  }

  ngOnDestroy(): void {
    if (!this._windowService.isMobile()) {
      this._store.dispatch(appActions.ToggleShowingSidenav({ payload: true }));
    }
  }
}
