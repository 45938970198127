import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsListComponent } from './components/events-list/events-list.component';
import { SharedModule } from '../../shared/shared.module';
import { MaterialModule } from '../../shared/material/material.module';
import { EventsRoutingModule } from './events-routing.module';
import { EventDetailsComponent } from './components/event-details/event-details.component';
import { TrimTextPipe } from '../../shared/pipes/trim-text/trim-text.pipe';
import { NewEventModule } from '../new-request/new-request.module';
import { EventRequestInboxComponent } from '../../shared/components/event-request-inbox/event-request-inbox.component';
import { UpsellsComponent } from '../event-request/components/event-request-overview/upsells/upsells.component';
import { EventItemComponent } from '../../shared/components/event-item/event-item.component';

@NgModule({
  declarations: [EventsListComponent, EventDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    EventsRoutingModule,
    TrimTextPipe,
    NewEventModule,
    EventRequestInboxComponent,
    UpsellsComponent,
    EventItemComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EventsModule {}
