<div class="upsells" *ngIf="upsells && upsellsList">
  <h2>Add more services for your {{ eventRequestTitle }}</h2>

  <div class="upsells-service-related" *ngIf="upsellsList && upsellsList.length">
    <p class="tw-mb-0" *ngIf="!eventUpsells">Customers who have requested {{ eventRequest?.primaryServiceTitle }} also booked these services:</p>
    <div class="upsells_grid">
      <div *ngFor="let upsell of upsellsList">
        <a (click)="upsellEvent('upsellClicked', upsell.name); openForm(upsell)" class="upsells_grid_element tw-cursor-pointer">
          <img [src]="upsell.imgUrl" [alt]="upsell.name" />
          <div class="tw-rounded-b-[12px] tw-border tw-border-solid tw-border-slate-300 tw-p-3 tw-text-left">
            <span class="upsells_grid_element_name"> {{ upsell.name }} </span>
            <div class="upsells_grid_element_rating">
              <ate-star size="small" color="orange"></ate-star>
              <span class="upsells_grid_element_rating_number">
                {{ upsell.rating }}
              </span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div class="upsells-event-type tw-mt-4" *ngIf="!eventUpsells && upsells?.eventType?.length">
    <p class="tw-mb-0">
      Customers organising
      <span *ngIf="eventRequest.event">{{ eventRequest?.event?.eventType?.toLowerCase().replace('_', ' ') }}s also booked these services:</span>
      <span *ngIf="!eventRequest.event">{{ eventRequest?.eventType?.toLowerCase().replace('_', ' ') }}s also booked these services:</span>
    </p>
    <div class="upsells_grid">
      <div *ngFor="let upsell of upsellsByEventType">
        <a (click)="upsellEvent('upsellClicked', affiliate.name); openForm(upsell)" class="upsells_grid_element tw-cursor-pointer">
          <img [src]="upsell.imgUrl" [alt]="upsell.name" />
          <div class="tw-rounded-b-[12px] tw-border tw-border-solid tw-border-slate-300 tw-p-3 tw-text-left">
            <span class="upsells_grid_element_name"> {{ upsell.name }} </span>
            <div class="upsells_grid_element_rating">
              <ate-star size="small" color="orange"></ate-star>
              <span class="upsells_grid_element_rating_number">
                {{ upsell.rating | number: '1.1-1' }}
              </span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
