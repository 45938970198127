import { Component, Input } from '@angular/core';
import { IUtilityMessageData, UtilityMessageConfig } from '../../models/misc';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-utility-message',
  templateUrl: './utility-message.component.html',
  styleUrls: ['./utility-message.component.scss']
})
export class UtilityMessageComponent {
  @Input() config: UtilityMessageConfig;

  onClick(event) {
    switch (event) {
      case 'invite':
        this.goToInvites(this.config.data);
        break;
    }
  }

  goToInvites({ eventRequestUuid }: IUtilityMessageData) {
    window.location.href = `${environment.webUrl}/request/${eventRequestUuid}/matches`;
  }
}
