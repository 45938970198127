import { Component, Inject, Input } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { EventOverviewPageConfig } from '../../models/event-overview-page-config.interface';

@Component({
  selector: 'app-event-request',
  templateUrl: './event-request-sheet.component.html',
  styleUrls: ['./event-request-sheet.component.scss']
})
export class EventRequestSheetComponent {
  @Input() public iconName = '';

  constructor(
    private _router: Router,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      iconName: string;
      route: ActivatedRoute;
      config: EventOverviewPageConfig;
    },
    private _bottomSheetRef: MatBottomSheetRef<EventRequestSheetComponent>
  ) {}

  openEventRequestDetails(): void {
    this.close();
    this._router.navigate(['details'], { relativeTo: this.data.route });
  }

  openCloseEventRequest(): void {
    this.close();
    this._router.navigate([{ outlets: { modalOutlet: ['close'] } }], { relativeTo: this.data.route });
  }

  close() {
    this._bottomSheetRef.dismiss();
  }
}
