import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventRequestSingleType, IAffiliate, IUpsell, NamedUpsells } from '../../../../../shared/services/event-request/event-request.type';
import { DatalayerService } from '../../../../../shared/services/datalayer/datalayer.service';
import { affiliates } from '../affiliates';
import { FirebaseFeatureFlags } from '../../../../../shared/enums/firebase-custom-claims.enum';
import { WindowService } from '../../../../../shared/services/window/window.service';
import { EnhancedFormsLauncherService } from '../../../../../shared/services/enhanced-forms-launcher/enhanced-forms-launcher.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-upsells',
  templateUrl: './upsells.component.html',
  styleUrls: ['./upsells.component.scss'],
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UpsellsComponent implements OnChanges, OnInit {
  @Input() eventRequest: EventRequestSingleType;
  @Input() upsells: NamedUpsells;
  @Input() eventUpsells: boolean;

  affiliate: IAffiliate;
  currentRouteRequestUuid: string;
  eventRequestTitle: string;
  FirebaseFeatureFlags = FirebaseFeatureFlags;
  upsellsList: IUpsell[];
  upsellsByEventType: IUpsell[];

  constructor(
    private router: Router,
    private _dataLayer: DatalayerService,
    private route: ActivatedRoute,
    private _windowService: WindowService,
    private _enhancedFormsLauncherService: EnhancedFormsLauncherService
  ) {}

  ngOnInit() {
    this.currentRouteRequestUuid = this.route.snapshot.paramMap.get('eventRequestUuid');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.eventRequest) {
      this.eventRequestTitle = this.eventRequest.eventType
        ? this.eventRequest.eventType.toLowerCase().split('_').join(' ')
        : this.eventRequest.event.eventType
          ? this.eventRequest.event.eventType.toLowerCase().split('_').join(' ')
          : 'event';

      this.affiliate = this.getAffiliate();
      if (changes.eventUpsells) {
        this.upsellsList = this.setUpsells(this.eventUpsells, this.upsells);
        this.upsellsByEventType = this.setUpsellsByEventType(this.upsells.eventType);
      }
    }
  }

  setUpsells(eventUpsells: boolean, upsells: NamedUpsells): IUpsell[] {
    const combinedUpsells: IUpsell[] = [...upsells.serviceRelated, ...upsells.eventSpecific];
    const uniqueUpsells: IUpsell[] = combinedUpsells.filter((item, index, self) => index === self.findIndex(t => t.tid === item.tid));
    const returnedUpsells = (eventUpsells ? uniqueUpsells : upsells.serviceRelated).slice(0, 12);

    if (returnedUpsells.length % 2 !== 0) {
      return returnedUpsells.slice(0, returnedUpsells.length - 1);
    }
    return returnedUpsells;
  }

  setUpsellsByEventType(upsellsByEventType: IUpsell[]): IUpsell[] {
    const returnedUpsells = upsellsByEventType.slice(0, 12);

    if (returnedUpsells.length % 2 !== 0) {
      return returnedUpsells.slice(0, returnedUpsells.length - 1);
    }
    return returnedUpsells;
  }

  goBack(): void {
    this.router.navigate(['/']);
  }

  upsellEvent(name: string, type: string): void {
    this._dataLayer.sendEvent(name, {
      type,
      eventRequestUuid: this.currentRouteRequestUuid
    });
  }

  /**
   * Opens the V3 form if we have a form definition for that event type. Falls back to the v1 form.
   *
   * Note that the form will automatically redirect to the new event on successful submission.
   */
  openForm(upsell: IUpsell) {
    const eventUuid: string = this.eventRequest.event ? this.eventRequest.event.uuid : this.eventRequest.eventUuid;
    if (upsell.v2FormUuid) {
      this._enhancedFormsLauncherService.launchForm({
        formUuid: upsell.v2FormUuid,
        eventUuid,
        mainEventUuid: eventUuid
      });
    } else {
      this._windowService.open(upsell.url, '_blank');
    }
  }

  getAffiliate(): IAffiliate {
    let affiliate = { ...this.eventRequest?.affiliate }; // Create a new object with the same properties
    if (affiliate?.title) {
      const foundAffiliate = affiliates.find(item => item.title === affiliate.title);
      if (foundAffiliate) {
        affiliate = { ...affiliate, ...foundAffiliate }; // Merge properties of found affiliate into the existing one
      }
    }
    return affiliate;
  }
}
