<section class="main-content" *ngIf="(isLoading | async) === false">
  <div class="image-range">
    <ate-icon (click)="goBack()" name="chevron-left-v2"></ate-icon>
    <app-social-context-menu [socials]="socialLinks"></app-social-context-menu>

    <app-carousel [mode]="1" class="image-carousel" [pips]="true" (touchend)="onMouseUp($event)" (mouseup)="onMouseUp($event)">
      <app-carousel-item *ngFor="let image of listing?.images | slice: 0 : 10; let i = index">
        <img
          draggable="false"
          [src]="image | replace: '{style}' : 'one_half'"
          (touchstart)="openImageLightbox(i, $event)"
          (mousedown)="openImageLightbox(i, $event)" />
      </app-carousel-item>
    </app-carousel>

    <app-lightbox>
      <app-lightbox-image *ngFor="let image of listing?.images" [url]="image | replace: '{style}' : 'x_large'"> </app-lightbox-image>
    </app-lightbox>
  </div>

  <h1>{{ listing?.title }}</h1>

  <div class="meta">
    <ate-star color="orange"></ate-star>
    <a fragment="reviews" (click)="navigateToSection('reviews')">{{ listing?.reviewRating }} </a>
    <a fragment="reviews" (click)="navigateToSection('reviews')">({{ listing?.reviewCount }} reviews) </a>
    <span *ngIf="listing?.address?.city && listing?.address?.region">•</span>

    <a fragment="business" (click)="navigateToSection('business')">
      <span *ngIf="listing?.address?.city">{{ listing?.address?.city }},</span>
      {{ listing?.address?.region }}
    </a>
  </div>

  <a class="phone" [href]="'tel:' + listing?.phoneNumber">
    <ate-icon name="phone"></ate-icon>
    <span>Call Supplier</span>
  </a>

  <h2 class="subtitle" *ngIf="listing?.subtitle">
    {{ listing?.subtitle }}
  </h2>

  <section class="about-listing" *ngIf="listing?.description">
    <h3>About {{ listing?.title }}</h3>
    <app-read-more [details]="listing?.description"></app-read-more>
  </section>

  <section *ngIf="listing?.clients" class="typical-clients">
    <h3>Typical Clients</h3>
    <p>{{ listing?.clients }}</p>
  </section>

  <section id="reviews" class="review" *ngIf="listing?.reviews.length">
    <header>
      <ate-star color="orange"></ate-star>
      <span>{{ listing?.reviewRating }}</span>
      <span>({{ listing?.reviewCount }}) reviews</span>
    </header>

    <app-carousel [perPage]="listing?.reviews.length === 1 ? 1 : 1.2">
      <app-carousel-item *ngFor="let review of listing?.reviews">
        <app-review-list-item
          [review]="review"
          [toggleCallback]="toggleReviewCallback(review.uuid)"
          [moreText]="'View Full Review'"></app-review-list-item>
      </app-carousel-item>
    </app-carousel>

    <a class="full-reviews" (click)="goToListingReview()">
      <span>View All Reviews</span>
    </a>
  </section>

  <section id="reviews" class="review" *ngIf="!listing?.reviews.length">
    <header class="no-reviews">No Reviews (yet)</header>

    <p>This supplier does not yet have any reviews</p>
  </section>

  <section id="business" class="business-details">
    <h3>Business Details</h3>

    <h4 *ngIf="listing?.address">Business Address:</h4>
    <p *ngIf="listing?.address">
      <span>{{ listing?.address?.line1 }}</span>
      <span>{{ listing?.address?.line2 }}</span>
      <span>{{ listing?.address?.city }}</span>
      <span>{{ listing?.address?.postcode }}</span>
      <span>{{ listing?.address?.country }}</span>
    </p>

    <h4>Insurance:</h4>
    <p>Please confirm with supplier</p>
  </section>

  <p class="terms">
    This information has been submitted by <b>{{ listing?.title }}</b
    >.<br />
    Please verify this information before going ahead with any<br />bookings. <a [href]="termsOfUseUrl">View our full terms and conditions</a>.
  </p>
</section>

<div class="fixed-button">
  <ate-button-control color="cta" (click)="goBack()">
    <ate-icon name="chat-message" slot="start"></ate-icon>
    Back to chat
  </ate-button-control>
</div>
