<ate-icon class="close tw-m-3 tw-mb-0 tw-ml-auto tw-h-6 tw-w-6 tw-cursor-pointer tw-text-slate-500" name="close-v2" (click)="close()"></ate-icon>
<div class="tw-m-4 tw-mt-0">
  <ul class="tw-m-0 tw-list-none tw-p-0">
    <li class="tw-flex tw-items-center tw-gap-3 tw-p-3">
      <ate-icon class="tw-h-6 tw-w-6 tw-text-slate-600" [name]="data.iconName"></ate-icon>
      <p class="tw-m-0 tw-text-base tw-font-bold tw-text-slate-600" (click)="openEventRequestDetails()">View Request Details</p>
    </li>
    <li class="tw-flex tw-items-center tw-gap-3 tw-p-3" *ngIf="data?.config?.closeServiceRequestButton">
      <ate-icon class="tw-h-6 tw-w-6 tw-text-red-700" name="circle-cross-outline-v3"></ate-icon>
      <p class="tw-m-0 tw-text-base tw-font-bold tw-text-red-700" (click)="openCloseEventRequest()">Cancel Service Request</p>
    </li>
  </ul>
</div>
